// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-attend-js": () => import("./../../../src/pages/attend.js" /* webpackChunkName: "component---src-pages-attend-js" */),
  "component---src-pages-call-for-speakers-js": () => import("./../../../src/pages/call-for-speakers.js" /* webpackChunkName: "component---src-pages-call-for-speakers-js" */),
  "component---src-pages-code-of-conduct-js": () => import("./../../../src/pages/code-of-conduct.js" /* webpackChunkName: "component---src-pages-code-of-conduct-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-organizers-js": () => import("./../../../src/pages/organizers.js" /* webpackChunkName: "component---src-pages-organizers-js" */),
  "component---src-pages-schedule-js": () => import("./../../../src/pages/schedule.js" /* webpackChunkName: "component---src-pages-schedule-js" */),
  "component---src-pages-scholarships-js": () => import("./../../../src/pages/scholarships.js" /* webpackChunkName: "component---src-pages-scholarships-js" */),
  "component---src-pages-speakers-js": () => import("./../../../src/pages/speakers.js" /* webpackChunkName: "component---src-pages-speakers-js" */),
  "component---src-pages-sponsors-js": () => import("./../../../src/pages/sponsors.js" /* webpackChunkName: "component---src-pages-sponsors-js" */)
}

